import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { QueryClientProvider } from "react-query";
import { HoneybadgerErrorBoundary } from "@honeybadger-io/react";

import App from "./App";
import CaughtErrorView from "./components/CaughtErrorView";
import reportWebVitals from "./reportWebVitals";
import honeybadger from "./honeybadger";
import queryClient from "./queryClient";

import { msalConfig } from "./auth/authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

if (
  window.location.hash !== "" &&
  new URLSearchParams(window.location.hash.slice(1)).has("code") &&
  new URLSearchParams(window.location.hash.slice(1)).has("session_state")
) {
  console.log("MSAL hash found" + window.location.hash);
} else {
  ReactDOM.render(
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>
        <HoneybadgerErrorBoundary
          honeybadger={honeybadger}
          ErrorComponent={CaughtErrorView}
        >
          <MsalProvider instance={msalInstance}>
            <QueryClientProvider client={queryClient}>
              <App />
            </QueryClientProvider>
          </MsalProvider>
        </HoneybadgerErrorBoundary>
      </I18nextProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
