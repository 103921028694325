import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../auth/authConfig";
import { Button } from "@op/opux";
import Login from "./Login";
import { useTranslation } from "react-i18next";

export const SignInButton = () => {
  const { instance } = useMsal();
  const { t } = useTranslation();
  const handleLogin = (loginType) => {
    if (loginType === "popup") {
      instance
        .loginPopup(loginRequest)
        .then((result) => {
          console.log(result);
        })
        .catch((e) => {
          console.warn(e);
        });
    } else if (loginType === "redirect") {
      instance
        .loginRedirect(loginRequest)
        .then((result) => {
          console.log(result);
        })
        .catch((e) => {
          console.warn(e);
        });
    }
  };
  return (
    <div>
      <Login>
        <Button
          className="width-100"
          onClick={() => handleLogin("popup")}
          primary
        >
          {t("general.login")}
        </Button>
      </Login>
      {/*<Button onClick={() => handleLogin("redirect")}>
        Sign in using Redirect
      </Button>*/}
    </div>
  );
};
