import { IconRestaurant } from "@op/opux-icons/misc";
import { IconHelp } from "@op/opux-icons/action";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "./LanguageSwitcher";
import squares from "../icons/squares.png";
import house from "../icons/house.png";
import { ReactComponent as OPLogo } from "../icons/op-logo.svg";

import "./login.css";
const Login = ({ children }) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  console.log(language);
  const IconWithText = ({ icon, text }) => {
    return (
      <div style={{ display: "flex" }}>
        {icon}
        <div style={{ float: "left" }}>
          <p style={{ display: "inline-block" }}>{text}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="login-container">
      <LanguageSwitcher inline />

      <div className="child-margins-y-16">
        <div>
          <div className="flex">
            <div style={{ flex: 1 }}>
              <OPLogo style={{ height: 50, width: 50, float: "right" }} />
            </div>
            <div style={{ flex: 1 }}></div>
          </div>
          <div className="flex">
            <div style={{ flex: 1 }}></div>
            <div style={{ flex: 1 }}>
              <img alt="" style={{ height: 50, width: 50 }} src={house} />
            </div>
          </div>
        </div>

        <h1 className="margin-12 text-center" style={{ lineHeight: 1.2 }}>
          {t("landing.title")}
        </h1>
        <h3 className="text-center" style={{ color: "#a4a4a4" }}>
          {t("landing.subtitle")}.
        </h3>

        <IconWithText
          text={t("landing.first_bullet")}
          icon={
            <img
              alt=""
              src={squares}
              style={{
                height: 50,
                width: 50,
                position: "relative",
                marginRight: 12,
                bottom: 3,
              }}
            />
          }
        />
        <IconWithText
          text={t("landing.second_bullet")}
          icon={
            <div style={{ padding: 12 }} className="login-icon-container">
              <IconHelp
                style={{
                  height: 30,
                  width: 30,
                  color: "red !important",
                  position: "relative",
                  right: 3,
                  bottom: 3,
                }}
              />
            </div>
          }
        />
        <IconWithText
          text={t("landing.third_bullet")}
          icon={
            <div style={{ padding: 12 }} className="login-icon-container">
              <IconRestaurant
                style={{
                  height: 30,
                  width: 30,
                  position: "relative",
                  right: 3,
                  bottom: 3,
                }}
              />
            </div>
          }
        />
        <h3 className="text-center" style={{ color: "#a4a4a4" }}>
          {t("landing.footer")}
        </h3>
        <div>{children}</div>
        <p className="text-center">
          Lataa Korttelisovellus
          <br />
          <span>
            <a
              href={`https://play.google.com/store/apps/details?id=fi.op.kortteliapp&hl=${language}&gl=US`}
            >
              Google Play -kaupasta
            </a>
          </span>{" "}
          ja
          <span>
            <a
              href={`https://apps.apple.com/fi/app/op-korttelisovellus/id1560157814`}
            >
              {" "}
              App Storesta
            </a>
          </span>
        </p>
      </div>
    </div>
  );
};

export default Login;
